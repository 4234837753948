import React from 'react'

const Aboutus = () => {
  return (
    <div className='container'>
      <div className="bg-dark mt-3 text-white text-center py-5">
        <h1>Gutsyme</h1>
        <p className="lead">Where Comfort Meets Style</p>
      </div>

      <div className=" mt-5">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <p className="text-justify">
              Introducing Gutsyme, where comfort meets style in every stitch.
              Gutsyme is a dynamic brand that redefines fashion with its
              collection of oversized t-shirts for both men and women. Born out
              of the desire to embrace individuality and empower
              self-expression, Gutsyme celebrates the bold and the confident.
            </p>

            <p className="text-justify">
              At the heart of Gutsyme is a commitment to providing unparalleled
              comfort without compromising on style. Our oversized t-shirts are
              more than just garments; they are a statement of confidence and
              authenticity. Crafted from premium fabrics, each piece is
              designed to drape effortlessly, ensuring a comfortable fit for all
              body types. Gutsyme's dedication to quality ensures that every
              t-shirt becomes a wardrobe staple, a versatile piece that
              effortlessly transitions from casual outings to laid-back weekends.
            </p>

            <p className="text-justify">
              Gutsyme's design philosophy is rooted in simplicity with a touch
              of edginess. The oversized silhouette is not just a trend but a
              reflection of the brand's ethos—celebrating individuality and
              embracing one's unique shape and size. Our diverse range of colors
              and patterns allows our customers to express themselves freely,
              whether they prefer classic neutrals or vibrant hues.
            </p>

            <p className="text-justify">
              More than just a clothing brand, Gutsyme is a lifestyle that
              encourages wearers to embrace their true selves and live
              unapologetically. The brand transcends fashion norms, inviting
              individuals to be comfortable in their skin and in their clothes.
              Gutsyme believes that confidence starts with comfort, and our
              oversized t-shirts are the canvas for individuals to showcase
              their fearless spirit.
            </p>

            <p className="text-justify">
              Step into the world of Gutsyme, where oversized isn't just a
              size—it's a mindset. Join us in celebrating the gutsy, the bold,
              and the unapologetically authentic. Because with Gutsyme, every
              t-shirt tells a story, and yours is waiting to be written.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Aboutus
