import React, { useState ,useContext } from 'react'
import './Sign.css'
import { Link, useNavigate } from 'react-router-dom';
  import { toast } from 'react-toastify';
  import { ToastContainer } from 'react-toastify';
import { LoginContext } from '../Context/ContextProvider';
import EmailOtpVerification from './EmailOtpVerification';
const SignIn = () => {

  const navigate = useNavigate();
    const [logData,setLogData] = useState({
        email:"",
        password:""
    })
// console.log(logData);


const {account ,setAccount} =useContext(LoginContext)

const AddData =(e)=>{
const {name,value} = e.target;

setLogData(()=>{

    return{
        ...logData,
        [name]:value
    }
})
}


const sendData =async(e)=>{
  e.preventDefault();

  const {email,password}  = logData;

  // if (password.length >= 6) {
  //   toast.error("password should be of 6 elements");
  //   return; // Exit the function if validation fails
  // }
  
  const res = await fetch(`${process.env.REACT_APP_API}/login` ,{
    method:"POST",
    headers:{
      "Content-Type":"application/json"
    },
    credentials: 'include',
    body: JSON.stringify({
      email,password
    })
  })
  const data = await res.json();
  // console.log(data);


  if(res.status === 400 ){
    toast.error("password is wrong")
  }
  else if(res.status === 401 ){
    toast.error("Invalid details")
  }
  else if(!data){
    toast.error("kindly fill all the fields")
  }
  else{
    toast.success("you are logged in!")
    setAccount(data);
    setLogData({...logData,email:"",password:""})
    navigate('/');
  }
}

  return (
    <div className='sign_in d-flex justify-content-between'>
      <div className='d-flex justify-content-center'>
        <EmailOtpVerification/>
      </div>
      <div className='text-white fs-3 fw-bold'>
        Or
      </div>
        <div className='d-flex flex-column  signin_form'>
      <form action="" method='POST' className=' d-flex flex-column form   '> 
        <h1>SignIn</h1>
        <input type="text" name='email' id='email' onChange={AddData} value={logData.email} placeholder='enter Email address'  />
        <input type="password" name='password' id='password' onChange={AddData} value={logData.password} placeholder='enter password'  />
        <button onClick={sendData}>Continue</button>
      </form>
      <p>New on gutsyme ?<Link className='text-danger fw-bold' style={{textDecoration:'none'}} to='/signup'>  Register here</Link></p>
      </div>
      <ToastContainer />
    </div>
  )
}


export default SignIn
