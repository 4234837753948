import Carousel from 'react-bootstrap/Carousel';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './banner.css'

const Banner = () => {
  const [banners, setBanners] = useState([]);

  

  const getBanner = async () => {
    try {
      const banner = await fetch(`${process.env.REACT_APP_API}/getbanner`);
      if (banner.ok) {
        const data = await banner.json();
        setBanners(data);
        // console.log(banners);
      } else {
        console.error('Error fetching categories:', banner.statusText);
      }
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  useEffect(() => {
    getBanner();
  }, []);

  return (
    <>
    <div className='Desktop-banner p-0 m-0'>
    <Carousel  slide={true} pause={false} >
        {banners.map((banner) => (

          <Carousel.Item key={banner._id}>
            <Link to={`/productone/${banner.productId}`} style={{textDecoration:'none', display:'block'}} className='product-head h-100 mx-0 my-0 py-0'>
            <img
              className="d-block w-100"
              src={banner.Dlink}
              alt="desktop"
            />
            </Link>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
      <div className='Mobile-banner '>
      <Carousel  slide={true} pause={false}>
        {banners.map((banner) => (

          <Carousel.Item key={banner._id}>
            <Link to={`/productone/${banner.productId}`} style={{textDecoration:'none', display:'block'}} className='product-head h-100 mx-0 my-0 py-0'>
            <img
              className="d-block w-100"
              src={banner.Mlink}
              alt="mobile"
            />
            </Link>
          </Carousel.Item>
        ))}
      </Carousel>
      </div>
      
    </>
  );
};

export default Banner;
