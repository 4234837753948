import React, { useState } from 'react'
import Banner from './Banner'
import Slide from './Slide'
import { useEffect } from 'react';
import { getProducts } from '../Redux/actions/Actions';
import { useDispatch,useSelector } from 'react-redux';

const Maincomp = () => {
const[deals,setDeals] = useState([]);
  const {products} = useSelector(state => state.getproductsdata);
  // console.log(products);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProducts());
    fetchDeals();
  }, [dispatch])



  const fetchDeals = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API}/getdeals`);
      if (response.ok) {
        const data = await response.json();
        setDeals(data);
      } else {
        console.error('Error fetching categories:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const filterProductsByDeal = (dealCategory) => {
    return products.filter((product) => product.deal === dealCategory);
  };
  
  return (
    <div className='home_section'>
        <div className="banner_part">
        <Banner />
        </div>
        <div className="slider mb-4">
        {deals.map(deal => (
            <Slide key={deal._id} title={deal.name}  products={filterProductsByDeal(deal._id)}/>
            ))}
        </div>
      
    </div>
  )
}

export default Maincomp
