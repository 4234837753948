import React, { useState } from 'react'
import './Sign.css';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
const SignUp = () => {

    const [userData ,setUserdata] = useState({
        name:"",
        email:"",
        number:"",
        password:""
    })

    // console.log(userData);

    const AddUserData =(e)=>{
        const {name,value} = e.target;

        setUserdata(()=>{
            return{
                ...userData,
                [name]:value
            }
           
        })
    }

    const sendData =async(e)=>{
      e.preventDefault();


      


      const {name,email,number,password}  = userData;

      if (number.length !== 10) {
        toast.error("Please enter a 10-digit number");
        return; // Exit the function if validation fails
      }
      
      const res = await fetch(`${process.env.REACT_APP_API}/signup` ,{
        method:"POST",
        headers:{
          "Content-Type":"application/json"
        },
        body: JSON.stringify({
          name,email,number,password
        })
      })
      const data = await res.json();
      // console.log(data);


      if(res.status === 422 ){
        toast.error("you already have an account on gutsyme with same number")
      }
      else if(res.status === 421 ){
        toast.error("you already have an account on gutsyme with same email")
      }
      else if(!data){
        toast.error("kindly fill all the fields")
      }
      else{
        toast.success("congralutions your Account has been created")
        setUserdata({...userData,name:"",email:"",number:"",password:""})
      }
    }

  return (
    <div className='sign_in'>
    <div className='d-flex flex-column  signin_form'>
  <form method='POST' className=' d-flex flex-column form   '> 
    <h1>SignUp</h1>
    <input type="text" name='name' onChange={AddUserData} value={userData.name} id='name' placeholder='enter full name'  />
    <input type="text" name='email' onChange={AddUserData} value={userData.email} id='email' placeholder='enter Email address'  />
    <input type="text" name='number' onChange={AddUserData} value={userData.number} id='number' placeholder='enter contact number'  />
    <input type="password" name='password' id='password' onChange={AddUserData} value={userData.password} placeholder='enter password'  />
    <button onClick={sendData}>Register</button>
  </form>
  <p>Already have an account ?<Link className='text-danger' to='/signin' style={{textDecoration:'none'}}>  Click here</Link></p>
  </div>
  <ToastContainer />

</div>
  )
}

export default SignUp
