import React from 'react'
import { useEffect,useState } from 'react';
const Total = ({itemm}) => {
    const[price,setPrice] = useState(0);
    

      useEffect(() => {
      totalAmount();        
      }, [itemm])

      const totalAmount=()=>{
        let price=0;
        itemm.map((item)=>{
          price+=item.price.cost*item.quantity
          setPrice(price);
        })
      }
      
  return (
    <p className='sub-total text-end my-4'>subtotal({itemm.length}items) &nbsp;:<span className='fw-bold'>&nbsp;Rs.&nbsp;{price}.00</span></p>    
  )
}

export default Total
