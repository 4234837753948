import React from 'react'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Link } from 'react-router-dom';
// import { products } from './Productdata';
import './slide.css';
import { useState,useContext } from 'react';
import '../Filters/ProductFilter.css';
import SizeSelector from '../Product/SizeSelector';  // Import SizeSelector component
import QuantityControl from '../Product/QuantityControl';  // Import QuantityControl component
import { toast, ToastContainer } from 'react-toastify';
import { LoginContext } from '../Context/ContextProvider';
import { useNavigate } from 'react-router-dom';



const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2
    }
  };



const Slide = ({title,products}) => {
  const { account, setAccount } = useContext(LoginContext);

  const [quantity, setQuantity] = useState(1);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);


  const navigate = useNavigate();


  const handleQuantityChange = (newQuantity) => {
    setQuantity(newQuantity);
  };

  const handleAddToCartClick = (product) => {
    setSelectedProduct(product);
    setIsPopupOpen(true);
  };
 



  // Function to handle "Add to Cart" button click in the popup
  const handleAddToCartPopupClick = async (id) => {
    // Add your API call here to send data to the database
    if (!selectedProduct.selectedSize) {
      toast.error("Please select a size");
      return; // Stop execution if no size is selected
    }
    const response = await fetch(`${process.env.REACT_APP_API}/addcart/${id}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
          selectedProduct,
          selectedSize: selectedProduct.selectedSize,
          quantity: quantity,
        }),
        credentials: "include"
      });

      if(!response.ok){
        toast.warn("please login into your account")
        // console.log("no data");
        setTimeout(() => {
          navigate('/signin');
        }, 500);
        
      }
      //  console.log(response);
      const data1 = await response.json();
      //  console.log(data1);
  if(!response.ok){
    alert("no data");
    // console.log("no data");
    navigate('/signin');
  }
  else{
    toast.success("product added")
    setAccount(data1)
          navigate('/cart');
  }

    setIsPopupOpen(false);
  };


  return (
    <div className='my-5'>
    <h1 className='top-selling'>{title}</h1>
    <Carousel responsive={responsive}
    swipeable={true}
    infinite={true}
    keyBoardControl={true}
    removeArrowOnDeviceType={["tablet", "mobile"]}
    // autoPlay={true}
    // autoPlaySpeed={2500}
    itemClass="carousel-item-padding-40-px"
    className=' h-100'
    >
  {
    products.map((e)=>{
        return(
          <div className="product  text-center mx-auto">
          <Link to={`/productone/${e.id}`} style={{textDecoration:'none'}} className='product-head mx-2'>
            <div className="product-card mx-2">
                <div className="product-image1">
                <img
                    src={e.url} // Set the initial image URL
                    alt=""
                    className=''
                    onMouseEnter={(event) => e.url1 && (event.target.src = e.url1)} // Change the image URL on hover if url1 exists
                    onMouseLeave={(event) => (event.target.src = e.url)} 
                  />
                </div>
                <p className='product-name text-dark mt-2'>{e.title.longTitle}</p>
                <div className='d-flex flex-row justify-content-center my-2'>
                  <p className='fw-bold text-dark mrp'>Rs.{e.price.mrp}.00</p>&nbsp;&nbsp;
                  <p className='text-danger fw-bold'>Rs.{e.price.cost}.00</p>
                </div>
               
            </div>
            
            </Link>
            <button className='buy-now mt-2 mb-4 mx-auto ' style={{"width":"90%"}} onClick={() => handleAddToCartClick(e)}>
                Add to Cart
              </button>
            </div>
        )
    })
    }
</Carousel>


{/* Popup for adding size and quantity */}
{isPopupOpen && selectedProduct && (
        <div className='address-bg'>
          <div className="address-form-too">
            <div className='inner-too'>
              <div className='w-30'><img src={selectedProduct.url} alt="" className='address-img'/></div>
              <div className='w-70'>

              <p style={{'font-size':'18px' , 'font-weight':'500'}}>{selectedProduct.title.longTitle}</p>
            
            {/* Include SizeSelector and QuantityControl components */}
            <SizeSelector
              sizes={['S', 'M', 'L', 'XL']}
              selectedSize={selectedProduct.selectedSize}
              onSelectSize={(size) => setSelectedProduct({ ...selectedProduct, selectedSize: size })}
              required
            />
            
            <QuantityControl
              onQuantityChange={handleQuantityChange}
              quantity={quantity}
            />
            
            {/* Add "Add to Cart" button in the popup */}
            <button onClick={() => handleAddToCartPopupClick(selectedProduct.id)} className='btn btn-dark '>Add to Cart</button>
            
            <button onClick={() => setIsPopupOpen(false)} className=' ms-3 btn border border-2'>Close</button>

              </div>
            </div>
           
          </div>
        </div>
      )}
      
      <ToastContainer />
</div>
  )
}

export default Slide
