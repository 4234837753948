import React, { useState } from 'react'
import './footer.css';
import { useNavigate } from 'react-router-dom';
import logo from '../Assets/logo.png'
import { NavLink } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { LoginContext } from '../Context/ContextProvider';
import { useContext } from 'react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import home  from '../Assets/home.png';
import orders from '../Assets/order.png';
import loginimg from '../Assets/login.png';
import logoutimg from '../Assets/logout.png';
import searchimg from '../Assets/search.png'
import facebook from '../Assets/face.png';
import instagram from '../Assets/insta.png';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Form from 'react-bootstrap/Form';


const Footer = () => {
  const {account ,setAccount} =useContext(LoginContext);
  const [search , setSearch] =useState(false);
  const [liopen,setLiopen] =useState(true);
  const[textt,setText] =useState("");
  const {products} = useSelector(state => state.getproductsdata);

const navigate = useNavigate();
  const logout =async()=>{
    const res2 = await fetch(`${process.env.REACT_APP_API}/logout`,{
      method:'GET',
          headers:{
            Accept: "application/json",
            "Content-Type": "application/json"
          },
          credentials: "include"
        });
        const data2 = await res2.json();
        // console.log(data2);
        if(res2.status !== 201 ){
          // console.log("not valid");
        }
        else{
          setAccount(false);
          toast.success("logout successfull")
          navigate('/');
        }
    
  }

  const handleLogout = async (event) => {
    event.preventDefault();
    await logout();
  };

  const handleEmailClick = () => {
    
    

  };

  const getText=(items)=>{
    setText(items);
    setLiopen(false);
      } 

  const searchfun =()=>{
        if (!search) {
          setSearch(true)
        }else{
          setSearch(false)
        }
      }

  return (
    <>
    <div className='footer'>
      <div className="container">
        <div className="footer-flex">
          <div className="flex-item px-3">
            {/* <h3 className='footer-brand'>GutsyMe</h3> */}
            <img src={logo} className='footer-logo my-2' alt="" />
            <p className='footer-50'>Gutsyme, the epitome of comfort and style, presents oversized t-shirts for men and women. Our premium fabrics ensure an effortlessly comfortable fit, celebrating individuality with simple yet edgy designs. From vibrant hues to classic neutrals, Gutsyme invites you to express your authentic self. Step into a world where oversized isn't just a size—it's a confident, bold mindset. Embrace comfort and style with Gutsyme's unique collection.</p>
            
            </div>
          <div className="flex-item">
            <ul className='footer-ul'>
              <li className='li-title my-4 '>Contact Us</li>
              <li className='ul-item mt-5 mb-3'><p onClick={handleEmailClick} className='ul-item'>Email us - gutsymein@gmail.com</p></li>
              <li className='ul-item my-3'><a target="_blank" href="https://api.whatsapp.com/send?phone=919082326835&text=Hello%20%F0%9F%91%8B%0A%20%20%2C%20I%20want%20more%20info%20about%20products" className='ul-item'>Whatsapp - +91 9082326835&nbsp;/&nbsp;8976242394</a></li>
              <li className='ul-item my-3 d-flex'>Socail Links -&nbsp;<a target="_blank" href="https://www.facebook.com/profile.php?id=61551800684965" className='ul-item mx-3'><img src={facebook} style={{'height':'24px' , 'width' : '24px'}}/></a><a target="_blank" href="https://www.instagram.com/gutsymeoffical?igsh=ZGZjeWhicDhndWEz&utm_source=qr" className='ul-item'><img src={instagram} style={{'height':'24px' , 'width' : '24px'}}/></a></li>
              <li></li>
            </ul>
          </div>
          <div className="flex-item ">
          <ul className='footer-ul'>
              <li className='li-title my-4 '>Category</li>
              <li className='ul-item mt-5 mb-3'>Home</li>
              <li className='ul-item my-3'>Collections</li>
              {account?<NavLink to="/myorders" className="ul-item "><div className="footer-item text-start">My Orders</div></NavLink>:<NavLink to="/signin" className="ul-item"><div className="footer-item text-start">My Orders</div></NavLink>}
              <li className='ul-item mt-3'><NavLink to="/aboutus" className="ul-item"><div className="footer-item text-start">About Us</div></NavLink></li>
            </ul>
          </div>
          
        </div>

        <div className='copyright'>
          <hr className='hr'/>
          <p className='text-white text-center fs-6'>© 2024, GutsyMe</p>
        </div>
      </div>
    </div>



    
    <div className="mobile-footer">
      <NavLink to="/" className="link mx-auto" onClick={()=>{setSearch(false)}}><div className="footer-item"><img src={home} alt="" className='img-fluid footer-icon' /></div></NavLink>
      <div className="link mx-auto" onClick={searchfun} ><div className="footer-item" onClick={()=>{setSearch(false)}}><img src={searchimg} alt="" className='img-fluid footer-icon' /></div></div>
      {account?<NavLink to="/myorders" className="link mx-auto" onClick={()=>{setSearch(false)}}><div className="footer-item"><img src={orders} alt="" className='img-fluid footer-icon' /></div></NavLink>:<NavLink to="/signin" className="link mx-auto"><div className="footer-item"><img src={orders} alt="" className='img-fluid footer-icon' /></div></NavLink>}
      {account?<Link class="link mx-auto" onClick={handleLogout}><span><img src={logoutimg} alt="" className='img-fluid footer-icon' onClick={()=>{setSearch(false)}}/></span></Link>:<Link to='/signin' class="link mx-auto"><img src={loginimg} alt="" className='img-fluid footer-icon' /></Link>}

      <ToastContainer />
    </div>

    {search ? (
      <div className='d-flex align-items-start justify-content-center h-100 w-100 pt-0' style={{'position':'fixed' , 'top':'0' ,'background':'rgb(0 0 0 / 59%)' , 'transition':'0.3s'}}  >
      <Form className="d-flex w-100 mx-auto mx-0 px-0" style={{'transition':'0.3s'}}>
      <Form.Control
        type="search"
        placeholder="Search"
        className=""
        aria-label="Search"
        style={{'height':'60px'}}
        onChange={(e)=>getText(e.target.value)}
      />
      {
        textt && <List className='extrasearch w-100 '   hidden={liopen}>
          {
            products.filter(product=>product.title.longTitle.toLowerCase().includes(textt.toLowerCase())).map(product=>(
              <ListItem >
                <NavLink to={`/productone/${product.id}`} className="text-dark" style={{"textDecoration":"none"}} onClick={()=>{setLiopen(true); setSearch(false)}}>
                  <div className='d-flex align-items-center'>
                    <div className='w-25'>
                    <img src={product.url} alt={product.title.longTitle} className='img-fluid p-1' style={{'height':'70px'}}/>
                    </div>
                    <div className='w-75 px-2' style={{'fontSize':'13px'}}>
                    {product.title.longTitle}
                    </div>

                  </div>
                
                </NavLink>
              </ListItem>
            ))
          }
        </List>
      }
      
      
    </Form>
    </div>
    ):('')}
    
    </>
  )
}

export default Footer
