
import './App.css';
import Footer from './components/Footer/Footer';
import Maincomp from './components/Home/Maincomp';
import Navbaar from './components/header/Navbaar';
import 'bootstrap/dist/css/bootstrap.min.css';
import {BrowserRouter , Routes , Route} from 'react-router-dom';
import SignIn from './components/SignIn_SIgnUp/SignIn';
import SignUp from './components/SignIn_SIgnUp/SignUp';
import Product from './components/Product/Product';
import Cart from './components/Cart/Cart';
import AddressForm from '../src/components/Cart/AddressForm'
import CircularProgress from '@mui/material/CircularProgress';
import { useState } from 'react';
import { useEffect } from 'react';
import OrderConfirmed from './components/Cart/OrderConfirmed';
import Myorders from './components/Myorders/Myorders';
import ProductFilter from './components/Filters/ProductFilter';
import Aboutus from './components/Home/Aboutus';


function App() {

 

  

  const [data,setData] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setData(true);
    }, 2000);
    
  }, [])
  
  return (
    <>
    {
      data ? (
        <>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
    <Navbaar/>
    <Routes>
      <Route path='/' element={<Maincomp/>}/>
      <Route path='/signin' element={<SignIn/>}/>
      <Route path='/signup' element={<SignUp/>}/>
      <Route path='/productone/:id' element={<Product/>}/>
      <Route path='/cart' element={<Cart/>}/>
      <Route path='/myorders' element={<Myorders/>}/>
      <Route path='/orderconfirmed' element={<OrderConfirmed/>}/>
      <Route path='/Addressform' element={<AddressForm/>}/>
      <Route path='/aboutus' element={<Aboutus/>}/>
      <Route path='/productfilter/:category' element={<ProductFilter/>}/>
      
    </Routes>
    <Footer/>
    </BrowserRouter>
        </>
      ):(
        <div className='loading d-flex flex-column justify-content-center align-items-center' style={{"height":"100vh"}}>
<CircularProgress className='text-dark'/>
<h3 className='mt-2'>Loading</h3>
        </div>
      )
    }
    
  
   
   
    </>
  );
}

export default App;
