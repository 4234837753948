// SizeSelector.js

import React from 'react';
import './size.css';

const SizeSelector = ({ sizes, selectedSize, onSelectSize }) => {
  return (
    <div className="size-selector ">
      <label className='size-label me-2'>Select Size : </label>
      {sizes.map((size) => (
        <div
          key={size}
          className={`size-option ${selectedSize === size ? 'selected' : ''}`}
          onClick={() => onSelectSize(size)}
        >
          {size}
        </div>
      ))}
    </div>
  );
};

export default SizeSelector;
