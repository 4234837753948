import React, { useContext, useEffect } from 'react';
import './Product.css';
import { useState } from 'react';
import QuantityControl from './QuantityControl';
import SizeSelector from './SizeSelector';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { LoginContext } from '../Context/ContextProvider';
import { toast, ToastContainer } from 'react-toastify';
import CircularProgress from '@mui/material/CircularProgress';
import { Helmet } from 'react-helmet';
import Carousel from 'react-bootstrap/Carousel';



const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 0
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 0
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 0
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

const Product = () => {
  const navigate = useNavigate();
  const [quantity, setQuantity] = useState(1);
  const [isSizeSelected, setIsSizeSelected] = useState(false);
  const { account, setAccount } = useContext(LoginContext);
  const [selectedImage, setSelectedImage] = useState('');

  const { id } = useParams("");

  const [indData, setindData] = useState({});
  const [loading, setLoading] = useState(true);

  const getindData = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API}/getproductone/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json"
        }
      });

      if (!res.ok) {
        // console.log("data is not there");
      } else {
        const data = await res.json();
        // console.log("data is there", data);
        setindData(data);
        setLoading(false); // Set loading to false after data is fetched
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false); // Set loading to false in case of an error
    }
  };

  const [selectedSize, setSelectedSize] = useState('');

  const availableSizes = ['S', 'M', 'L', 'XL'];

  const handleSizeChange = (size) => {
    setSelectedSize(size);
    setIsSizeSelected(true);
  };

  const handleQuantityChange = (newQuantity) => {
    setQuantity(newQuantity);
  };

  useEffect(() => {
    setTimeout(() => {
      getindData();
    }, 1500);
  }, [id]);

  const addtocart = async (id) => {
      //  console.log(id);
       if (!isSizeSelected) {
        toast.error("Please select a size");
        return; // Stop execution if no size is selected
      }
    const check = await fetch(`${process.env.REACT_APP_API}/addcart/${id}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            indData,
            selectedSize,
            quantity
        }),
        credentials: "include"
    });

      if(!check.ok){
        toast.warn("please login into your account")
        // console.log("no data");
        setTimeout(() => {
          navigate('/signin');
        }, 500);
        
      }
      // console.log(check);
      const data1 = await check.json();
      // console.log(data1);
  if(!check.ok){
    alert("no data");
    // console.log("no data");
    navigate('/signin');
  }
  else{
    toast.success("product added")
    setAccount(data1)
    navigate('/cart');
   
  }
  
};
const handleImageClick = (image) => {
  setSelectedImage(image);
};


  return (
    <>
    <Helmet>
        <title>{indData.title?.longTitle || 'Product Page'}</title>
        <meta name="robots" content="index, follow" />
        <meta name="description" content={indData.headDescription || 'Product description'} />
        <meta name="keywords" content={indData.keywords || 'product, key, words'} />
      </Helmet>
      {Object.keys(indData).length > 0 ? (
        <div className='container product-page mt-4'>
          <div className="row">
            <div className="col-lg-6">
              <div className='pro-flex'>
            <div className="main-image">
                <img src={selectedImage || indData.url} alt="" className='w-100 img-fluid product-image' />
              </div>


              <div className="main-image-m">
              <Carousel slide={true} pause={false}
    >
        <Carousel.Item>
        <img src={ indData.url || indData.url4 || indData.url3 || indData.url2 || indData.url1 } alt="" className='w-100 img-fluid product-image' />
          </Carousel.Item>
          <Carousel.Item>
        <img src={ indData.url1 || indData.url4 || indData.url3 || indData.url2 || indData.url } alt="" className='w-100 img-fluid product-image' />
          </Carousel.Item>
          <Carousel.Item>
        <img src={ indData.url2 || indData.url4 || indData.url3 || indData.url1 || indData.url } alt="" className='w-100 img-fluid product-image' />
          </Carousel.Item>
          <Carousel.Item>
        <img src={ indData.url3 || indData.url4 || indData.url2 || indData.url1 || indData.url } alt="" className='w-100 img-fluid product-image' />
          </Carousel.Item>
          <Carousel.Item>
        <img src={ indData.url4 || indData.url3 || indData.url2 || indData.url1 || indData.url } alt="" className='w-100 img-fluid product-image' />
          </Carousel.Item>
              
              </Carousel>
              </div>



              <div className="gallery mb-3 mt-0 pro-flex1 px-2">
                {indData.url && <img
                  src={indData.url}
                  alt={`Product Image`}
                  className='gallery-image'
                  onClick={() => handleImageClick(indData.url)}
                />}
                {indData.url1 && <img
                  src={indData.url1}
                  alt={`Product Image 1`}
                  className='gallery-image'
                  onClick={() => handleImageClick(indData.url1)}
                />}
                {indData.url2 && <img
                  src={indData.url2}
                  alt={`Product Image 1`}
                  className='gallery-image'
                  onClick={() => handleImageClick(indData.url2)}
                />}
                {indData.url3 && <img
                  src={indData.url3}
                  alt={`Product Image 1`}
                  className='gallery-image'
                  onClick={() => handleImageClick(indData.url3)}
                />}
                {indData.url4 && <img
                  src={indData.url4}
                  alt={`Product Image 1`}
                  className='gallery-image'
                  onClick={() => handleImageClick(indData.url4)}
                />}
                </div>
                </div>
            </div>
            <div className="col-lg-6">
              <h2>{indData.title?.longTitle}</h2>
              <p className='price my-4'>
                <span className='old_price'>Rs. {indData.price?.mrp}</span>
                <span className='new_price mx-3'>Rs. {indData.price?.cost}</span>
                <span className='off-percent'>{indData.price?.discount}&nbsp;OFF</span>
              </p>
              <div>
                <SizeSelector
                  sizes={availableSizes}
                  selectedSize={selectedSize}
                  onSelectSize={handleSizeChange}
                  required
                />
              </div>
              <div>
                <p className='quality mt-4 mb-1'>Quality</p>
                <QuantityControl onQuantityChange={handleQuantityChange} quantity={quantity} />
              </div>
              <div className='d-flex flex-column'>
                <button className='add-cart my-2' onClick={() => addtocart(indData.id)}>
                  Add to cart
                </button>
                <button className='buy-now my-2'>Buy Now</button>
              </div>
              <div className='product-description my-4 mb-5'>
                <p className='quality'>Product Description</p>
                <p>{indData.description}</p>
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
      ) : (
        <div className='loading d-flex flex-column justify-content-center align-items-center' style={{ "height": "100vh" }}>
          <CircularProgress className='text-dark' />
          <h3 className='mt-2'>Loading</h3>
        </div>
      )}
      
    </>
  );
};

export default Product;
