import React, { useState ,useContext ,useEffect } from 'react';
import { LoginContext } from '../Context/ContextProvider';
import { useNavigate } from 'react-router-dom';

const AddressForm = () => {
  const {account ,setAccount} =useContext(LoginContext);
  const [address, setAddress] = useState('');
  const [name, setName] = useState('');
  const [number, setNumber] = useState('');
  const navigate = useNavigate();

  const isFormComplete = name.trim() !== '' && number.trim() !== '' && address.trim() !== '';
  // console.log(address);
  // console.log(name);
  // console.log(number);
  const payNow = async (e)  => {
    e.preventDefault(); // Prevent default form submission behavior
    const responseInitiatePayment = await fetch(`${process.env.REACT_APP_API}/initiatePayment`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              credentials: 'include',
            });
      
            if (responseInitiatePayment.ok) {
              const responseDataInitiatePayment = await responseInitiatePayment.json();
      
              // Assuming the response contains orderId and amount
              const { orderId, totalAmount, key_id, product_name, description, contact, name: customerName, email } = responseDataInitiatePayment;
      
              // Initiate Razorpay payment here using orderId and totalAmount
              const options = {
                key: key_id, // Replace with your actual Razorpay key
                amount: totalAmount,
                currency: 'INR',
                name: 'Your Order',
                description: description,
                order_id: orderId,
                handler: async function (response) {
                  // console.log(response);
      
                  const mode = 'paid'
                  // console.log(mode);

                  // After successful payment, proceed with checkout
                  const responseCheckout = await fetch(`${process.env.REACT_APP_API}/checkoutcustom`, {
                    
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    credentials: 'include',
                    body: JSON.stringify({
                      address,
                      name,
                      number,
                      paymentMode:mode,
                    }),
                  });
      
                  if (responseCheckout.ok) {
                    const responseDataCheckout = await responseCheckout.json();
      
                    // Handle successful checkout response
                    // setShowAddressForm(false);
                    setAccount(responseDataCheckout.user);
                    // Fetch updated cart data if needed
                    // getCartData();
                    navigate('/orderconfirmed'); // Assuming you have imported 'navigate' from 'react-router-dom'
                  } else {
                    // Handle checkout failure
                    console.error('Checkout failed');
                  }
                },
                prefill: {
                  name: customerName,
                  contact: number,
                  email: email,
                },
                theme: {
                  color: '#000', // Change this to your desired color
                  image:'https://i.ibb.co/bbxxtGv/gutsy-4.png',
                },
              };
      
              const razorpay = new window.Razorpay(options);
              razorpay.open();
            } else {
              // Handle payment initiation failure
              console.error('Payment initiation failed');
            }

  };

  const COD = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    const mode = 'Cash on Delivery'
    // console.log(mode);
    const responseCheckout = await fetch(`${process.env.REACT_APP_API}/checkoutcustom`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify({
        address,
        name,
        number,
        paymentMode:mode,
      }),
    });
  
    if (!responseCheckout.ok) {
      // Handle checkout failure
      console.error('Checkout failed');
    } else {
      // Handle successful checkout response
      // setShowAddressForm(false);
      // setAccount(responseDataCheckout.user);
      // Fetch updated cart data if needed
      const res = await responseCheckout.json();
      setAccount(res.user);
      setTimeout(() => {
        // console.log('Checkout successful');
 
      }, 1500);
  
      // Assuming you have imported 'navigate' from 'react-router-dom'
      navigate('/orderconfirmed');
    }
  };

  const[cartdata,setCartData] = useState([]);
  const[total,setTotal] = useState(0);
          
  const getCartData =async()=>{
    const res=await fetch(`${process.env.REACT_APP_API}/cartdetails`,{
      method:'GET',
      headers:{
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    });

    const data = await res.json();

    if (res.status !== 201) {
      // console.log("error");
    }
    else{
      // console.log(data);
      setCartData(data.userdata.carts);
      setTotal(data.total)
      // console.log(cartdata);
    }
  }
  const calculateDeliveryDate = () => {
    const orderDate = new Date(Date.now());
    const deliveryDate = new Date(orderDate.setDate(orderDate.getDate() + 7));
    return deliveryDate.toDateString();
  };

  useEffect(() => {
    getCartData();
  }, [])

  

  return ( 
    <>
      <div className=' d-flex justify-content-center align-items-center py-5 h-100'>
        <div className="address-form py-4 border d-flex flex-row align-items-center my-5">
          <div className='px-2'>
            <h2 className='fw-bold mb-3'>Order Details</h2>
            <div className='inner'>
          {cartdata &&
    cartdata.map((item) => (
      <div key={item._id} className='my-2 border px-2'>
        <div className='d-flex flex-row'>
          <div className='w-25'>
            <img
              src={item.url}
              style={{ height: '100px', width: '100px', objectFit: 'contain' }}
              alt={item.title.longTitle}
              className='p-2'
            />
          </div>
          <div className='w-75 py-4'>{item.title.longTitle}</div>
        </div>
      </div>
    ))}
    </div>
    <p className='text-end fw-bold fs-5'>Sub Total : Rs . <span className='text-danger'>{total}.00</span>  </p>
    <p className='text-end my-2 fw-bold'>
            Estimated Time of Delivery &nbsp;: <span className='text-success'>{calculateDeliveryDate()}</span>
          </p>

          </div>
          <form>
          <h2 className='fw-bold my-3'>Fill your details</h2>

            <label htmlFor="name" className='mt-2'>Enter Your Name</label>
            <input
              id='name'
              value={name}
              required
              className='form-control mt-2 border border-2'
              onChange={(e) => setName(e.target.value)}
            />
            <label htmlFor="number" className='mt-3'>Enter Your Number</label>
            <input
              id='number'
              value={number}
              required
              className='form-control mt-2 border border-2'
              onChange={(e) => setNumber(e.target.value)}
            />
            <label htmlFor="address" className='mt-3'>Enter Your Complete Address:</label>
            <textarea
              id="address"
              className='form-control mt-2 border border-2'
              required
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
            
            
            <div className='d-flex flex-column '>
            <button
              type="submit"
              onClick={payNow}
              className={`btn h-25 submit my-3 w-100  ${isFormComplete ? '' : 'disabled'}`}
              disabled={!isFormComplete}
            >
              Pay Now
            </button>

            <button
              type="submit"
              onClick={COD}
              className={`btn h-25 fs-6 add-cart my-2 ms-auto w-50 ${isFormComplete ? '' : 'disabled'}`}
              disabled={!isFormComplete}
            >
              Cash on Delivery
            </button>
            </div>
            
            <span className='address-text text-danger text-center mt-3'>
              Kindly, enter a complete address with a landmark, sector, street no or name, etc.
            </span>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddressForm;