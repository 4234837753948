import React, { useContext, useEffect, useState } from 'react';
import { LoginContext } from '../Context/ContextProvider';

const OrderConfirmed = () => {
  const [latestOrder, setLatestOrder] = useState({});
  const { account, setAccount } = useContext(LoginContext);
  // console.log(account);

  const findLatestOrder = () => {
    if (account && account?.orders?.length > 0) {
      const latest = account.orders.reduce((prev, current) =>
        new Date(current.orderDate) > new Date(prev.orderDate) ? current : prev
      );

      setLatestOrder(latest);
    }
  };

  useEffect(() => {
    findLatestOrder();
  }, [account]);

  const calculateDeliveryDate = () => {
    const orderDate = new Date(latestOrder.orderDate);
    const deliveryDate = new Date(orderDate.setDate(orderDate.getDate() + 7));
    return deliveryDate.toDateString();
  };

  return (
    <div className='d-flex flex-column justify-content-center align-items-center py-5'>
      <h1 className='order-head'>Order Confirmed!</h1>
      {latestOrder.items && latestOrder.items.length > 0 ? (
        <div className='mt-3 order-confirmed-div m-auto'>
          <p className='fs-5 fw-bold'>Your latest order details:</p>
          <ul>
            <p className='fw-bold order-number text-break'>Order No&nbsp;:&nbsp;{latestOrder._id}</p>
            {latestOrder.items.map((item) => (
              <li key={item._id} className='order-li'>
                <div className='row my-5 border p-3'>
                  <div className='col-lg-4 '>
                    <img
                      src={item.url}
                      alt=""
                      className='img-fluid'
                      style={{ height: '180px', width: '180px', objectFit: 'contain' }}
                    />
                  </div>
                  <div className='col-lg-8'>
                    <strong className='text-danger fs-5'>{item.title.longTitle}</strong> -{' '}
                    <span className='fw-bold fs-6'>Quantity&nbsp;</span>:{' '}
                    <span className='fs-6'>{item.quantity}</span> ,&nbsp; &nbsp;{' '}
                    <span className='fw-bold fs-6'>Selected Size&nbsp;</span>:&nbsp;
                    <span className='fs-6'>{item.selectedSize}</span>&nbsp;,&nbsp; &nbsp;
                    <span className='fw-bold fs-6'>Price&nbsp;</span>:{' '}
                    <span className='fs-6'>Rs.&nbsp;{item.price.cost ?? 'N/A'}</span>
                  </div>
                </div>
              </li>
            ))}
          </ul>
          <p className='text-end my-1'>
            {' '}
            <span className='fw-bold fs-5'>Total Amount&nbsp;:</span>&nbsp;{' '}
            <span className='fw-bold fs-5'>Rs.&nbsp;{latestOrder.totalAmount?.toFixed(2) ?? 'N/A'}</span>
          </p>
          {latestOrder.address && (
            <div className='text-end '>
              {' '}
              <span className='mt-2 mb-1 py-0 fs-6 fw-bold'>Shipping Address&nbsp;:&nbsp;</span>
              <span className='my-0 py-0 fs-6 text-break' style={{ fontWeight: '500' }}>
                {latestOrder.address}
              </span>
            </div>
          )}
          <p className='text-end my-2 fw-bold'>
            Estimated Time of Delivery &nbsp;: <span className='text-success'>{calculateDeliveryDate()}</span>
          </p>
        </div>
      ) : (
        <p>No order details available.</p>
      )}
    </div>
  );
};

export default OrderConfirmed;
