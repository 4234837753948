// QuantityControl.js

import React, { useState } from 'react';
import './quality.css'
const QuantityControl = ({ onQuantityChange, quantity }) => {
  const handleIncrement = () => {
    onQuantityChange(quantity + 1);
  };

  const handleDecrement = () => {
    if (quantity > 1) {
      onQuantityChange(quantity - 1);
    }
  };

  return (
    <div className='quality-div mt-2 mb-4'>
      <button onClick={handleDecrement} className='minus'>-</button>
      <span>{quantity}</span>
      <button onClick={handleIncrement} className='plus'>+</button>
    </div>
  );
};

export default QuantityControl;
