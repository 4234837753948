import React, { useContext, useEffect, useState } from 'react'
import './navbaar.css';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from '../Assets/logo.png';
import cart_icon from '../Assets/cart_icon.png';
import Avatar from '@mui/material/Avatar';
import { Link, NavLink } from 'react-router-dom';
import { LoginContext } from '../Context/ContextProvider';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import { useSelector } from 'react-redux';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { useNavigate } from 'react-router-dom';

const Navbaar = () => {

  const {account ,setAccount} =useContext(LoginContext);
  // console.log(account);
  const[textt,setText] =useState("");
  const [categories, setCategories] = useState([]);
  const [liopen,setLiopen] =useState(true);
  const {products} = useSelector(state => state.getproductsdata);
  // console.log(products);

  const navigate = useNavigate();
  


  const getvaliduser =async()=>{
    const res = await fetch(`${process.env.REACT_APP_API}/validuser`,{
      method:'GET',
          headers:{
            Accept: "application/json",
            "Content-Type": "application/json"
          },
          credentials: "include"
        });
        const data = await res.json();

        if(res.status !== 201 ){
          // console.log("not valid");
        }
        else{
          setAccount(data);
        }
    
  }

  const getText=(items)=>{
setText(items);
setLiopen(false);
  }

  useEffect(() => {
    getvaliduser();
    fetchCategories();
  }, [])


  const logout =async()=>{
    const res2 = await fetch(`${process.env.REACT_APP_API}/logout`,{
      method:'GET',
          headers:{
            Accept: "application/json",
            "Content-Type": "application/json"
          },
          credentials: "include"
        });
        const data2 = await res2.json();
        // console.log(data2);
        if(res2.status !== 201 ){
          // console.log("not valid");
        }
        else{
          setAccount(false);
          toast.success("logout successfull")
          navigate('/');
        }
    
  }

  const handleLogout = async (event) => {
    event.preventDefault();
    await logout();
  };

  // console.log(process.env.REACT_APP_API);

  const fetchCategories = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API}/getCategories`);
      if (response.ok) {
        const data = await response.json();
        setCategories(data);
      } else {
        console.error('Error fetching categories:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  
  return (
  <header>
    <div className="marquee-container w-100">
      <div className="marquee-text">
        <span>COD Available</span>
        <span>COD Available</span>
        <span>COD Available</span>
        <span>COD Available</span>
        <span>COD Available</span>
        <span>COD Available</span>
        </div>
    </div>
        <Navbar expand="lg" className="bg-body-tertiary">
      <Container fluid>
        <Link to="/"><Navbar.Brand href="/"><img src={logo} alt="" className='nav-logo' /></Navbar.Brand></Link>
        <Navbar.Toggle aria-controls="navbarScroll" />

        

        <Navbar.Collapse id="navbarScroll" className=''>
          <Nav
            className="me-auto my-2 my-lg-0"
            navbarScroll
          >
           <Link style={{textDecoration:'none'}} className='nav-item text-dark' to='/' >Shop</Link>

           {categories.map(category => (
           <Link style={{textDecoration:'none'}} className='nav-item text-dark' key={category._id} value={category._id} to={`/productfilter/${category._id}`} >{category.name}</Link>
           ))}
           
          </Nav>
          <Form className="d-flex">
            <Form.Control
              type="search"
              placeholder="Search"
              className="me-2"
              aria-label="Search"
              onChange={(e)=>getText(e.target.value)}
            />
            {
              textt && <List className='extrasearch'   hidden={liopen}>
                {
                  products.filter(product=>product.title.longTitle.toLowerCase().includes(textt.toLowerCase())).map(product=>(
                    <ListItem>
                      <NavLink to={`/productone/${product.id}`} className="text-dark" style={{"textDecoration":"none"}} onClick={()=>setLiopen(true)}>
                        <div className='d-flex align-items-center'>
                          <div className='w-25'>
                          <img src={product.url} alt={product.title.longTitle} className='img-fluid p-1' />
                          </div>
                          <div className='w-75 px-2' style={{'fontSize':'13px'}}>
                          {product.title.longTitle}
                          </div>

                        </div>
                      
                      </NavLink>
                    </ListItem>
                  ))
                }
              </List>
            }
            
            
          </Form>

    
        <div className="loginlogout my-3 ms-4">
          {account?<Link class="rainbow-btn" onClick={handleLogout}><span>Logout</span></Link>:<Link to='/signin' class="rainbow-btn"><span>Login</span></Link>}
       
        </div>
        
        
        </Navbar.Collapse>
        <div className='d-flex icon-mobile'>
          {account?<Link to="/cart"><div className="cart mx-4 my-2 ">
            <img src={cart_icon} alt="" className='img-fluid cart_icon'/>
            <div className="cart-count">
            {account?.carts?.length || 0}
            </div>
        </div></Link>:<Link to="/signin"><div className="cart mx-4 my-2 ">
            <img src={cart_icon} alt="" className='img-fluid cart_icon'/>
            <div className="cart-count">
            {account?.carts?.length || 0}
            </div>
        </div></Link> }
        
        
        <div className='avatar'>
          {
            account? <Avatar className='bg-primary ms-3 my-2'>{account.name?.[0]?.toUpperCase()}</Avatar>: <Avatar className=' ms-3 my-2'></Avatar>
        }
            
            </div>
            </div>
           
      </Container>
      <ToastContainer />
    </Navbar>
    </header>
  )
}

export default Navbaar
