  import React from 'react'
  import './Cart.css'
  import QuantityControl from '../Product/QuantityControl'
  import { useState,useEffect } from 'react'
  import { useNavigate } from 'react-router-dom';
  import Total from './Total';
  import Delete from './Delete';
  import CircularProgress from '@mui/material/CircularProgress';
  // import Razorpay from 'razorpay';



  const Cart = () => {
    const navigate = useNavigate();
      const [quantity, setQuantity] = useState(1);
      // console.log(quantity);    
        const handleQuantityChange = (newQuantity) => {
          setQuantity(newQuantity);
        };

        

        const[cartdata,setCartData] = useState([]);
        
        // console.log(cartdata.carts);
        
        const getCartData =async()=>{
          const res=await fetch(`${process.env.REACT_APP_API}/cartdetails`,{
            method:'GET',
            headers:{
              Accept: "application/json",
              "Content-Type": "application/json"
            },
            credentials: "include"
          });

          const data = await res.json();

          if (res.status !== 201) {
            // console.log("error");
          }
          else{
            setCartData(data.userdata.carts);
            setQuantity(data.length > 0 ? data[0].quantity : 1)
          }
        }


        const handleCheckout = () => {
          navigate('/addressform');
        };


        // const handleSubmit = async (address, name, number, paymentMode) => {
        //   try {
           
        //     if (paymentMode === 'cashOnDelivery') {
        //       // Directly proceed for Cash on Delivery
        //       const responseCheckout = await fetch('/checkout', {
        //         method: 'POST',
        //         headers: {
        //           'Content-Type': 'application/json',
        //         },
        //         credentials: 'include',
        //         body: JSON.stringify({
        //           address,
        //           name,
        //           number,
        //           paymentMode,
        //         }),
        //       });
        
        //       if (responseCheckout.ok) {
        //         // const responseDataCheckout = await responseCheckout.json();
        
        //         // Handle successful checkout response
        //         // setShowAddressForm(false);
        //         // setAccount(responseDataCheckout.user);
        //         // Fetch updated cart data if needed
        //         getCartData();
        //         setTimeout(() => {
        //           navigate('/orderconfirmed'); // Assuming you have imported 'navigate' from 'react-router-dom'
        //         }, 1500);
        //       } else {
        //         // Handle checkout failure
        //         console.error('Checkout failed');
        //       }
        //     } else if (paymentMode === 'payNow') {
        //       // Call the API to initiate payment
        //       const responseInitiatePayment = await fetch('/initiatePayment', {
        //         method: 'POST',
        //         headers: {
        //           'Content-Type': 'application/json',
        //         },
        //         credentials: 'include',
        //       });
        
        //       if (responseInitiatePayment.ok) {
        //         const responseDataInitiatePayment = await responseInitiatePayment.json();
        
        //         // Assuming the response contains orderId and amount
        //         const { orderId, totalAmount, key_id, product_name, description, contact, name: customerName, email } = responseDataInitiatePayment;
        
        //         // Initiate Razorpay payment here using orderId and totalAmount
        //         const options = {
        //           key: key_id, // Replace with your actual Razorpay key
        //           amount: totalAmount,
        //           currency: 'INR',
        //           name: product_name,
        //           description: description,
        //           order_id: orderId,
        //           handler: async function (response) {
        //             console.log(response);
        
        //             // After successful payment, proceed with checkout
        //             const responseCheckout = await fetch('/checkout', {
        //               method: 'POST',
        //               headers: {
        //                 'Content-Type': 'application/json',
        //               },
        //               credentials: 'include',
        //               body: JSON.stringify({
        //                 address,
        //                 name,
        //                 number,
        //                 paymentMode,
        //               }),
        //             });
        
        //             if (responseCheckout.ok) {
        //               const responseDataCheckout = await responseCheckout.json();
        
        //               // Handle successful checkout response
        //               // setShowAddressForm(false);
        //               setAccount(responseDataCheckout.user);
        //               // Fetch updated cart data if needed
        //               // getCartData();
        //               navigate('/orderconfirmed'); // Assuming you have imported 'navigate' from 'react-router-dom'
        //             } else {
        //               // Handle checkout failure
        //               console.error('Checkout failed');
        //             }
        //           },
        //           prefill: {
        //             name: customerName,
        //             contact: number,
        //             email: email,
        //           },
        //         };
        
        //         const razorpay = new window.Razorpay(options);
        //         razorpay.open();
        //       } else {
        //         // Handle payment initiation failure
        //         console.error('Payment initiation failed');
        //       }
        //     }
        //   } catch (error) {
        //     console.error('Error during payment and checkout:', error);
        //   }
        // };
        
        
        


        

        useEffect(() => {
          getCartData();
        }, [])

        
      
    return (
      <>
      { cartdata ? (
        cartdata.length ?( <><div className='container cart mb-5'>
        <div className='d-flex justify-content-between align-items-center mt-4'>
          <h1>Your Cart</h1>
          <a className='text-dark' href='/'>continue shopping</a>
        </div>
        <div className='d-flex mt-4'>
          <div className='product-details-title'>
          Product
          </div>
          <div className='quantity-change-title'>
          Quantity
          </div>
          <div className='total-title'>
          Total
          </div>

        </div>
        <hr />

        {
          cartdata.reverse().map((e,k)=>{
            return(
              <>
              <div className='d-flex  cart-item my-4  py-3'>
          <div className='product-details'>
          <div className='cart-flex '>
              <div><img src={e.url} alt="" className='img-fluid mb-3 ' style={{height:'200px', "width":"200px",objectFit:'contain    '}} /></div>
              <div className='ms-3'>
                  <p className='title'>{e.title.longTitle}</p>
                  <p className='title'><span className='fw-bold fs-6'>Size:</span> {e.selectedSize}</p>
                
                  <div className='quantity-change-M d-flex justify-content-center align-items-center'>
          <QuantityControl onQuantityChange={handleQuantityChange} quantity={e.quantity} />
      <Delete deleteData={e.id} get={getCartData}/>
          </div>
              </div>
              
          </div>
          </div>
          <div className='quantity-change-D d-flex justify-content-center align-items-center'>
          <QuantityControl onQuantityChange={handleQuantityChange} quantity={e.quantity} />
          <Delete deleteData={e.id} get={getCartData}/>
          </div>
          <div className='total'>
      <p>Rs . {e.price.cost*e.quantity}.00</p>
          </div>

        </div>
              </>
            )
          })
        }
        
      </div>
      <div className='container mb-5'>
          <div className='d-flex flex-column justify-content-end'>
      <Total itemm={cartdata}/>
      <p className='w-100 text-end'><button className='checkout' onClick={handleCheckout}>Checkout</button></p>
      </div>
      </div> </>): (
        <div className='d-flex justify-content-center align-items-center' style={{"height":"80vh"}} >
        <h1>cart is empty</h1>
      </div>
  )
      
      ):(
        
      <div className='loading d-flex flex-column justify-content-center align-items-center' style={{ "height": "100vh" }}>
      <CircularProgress className='text-dark' />
      <h3 className='mt-2'>Loading</h3>
    </div>
      )}
      {/* {showAddressForm && (
          <AddressForm
            onAddressSubmit={handleSubmit}
            onCancel={handleAddressFormCancel}
          />
        )} */}

      </>
    )
  }

  export default Cart
