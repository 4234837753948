import React, { useEffect, useState ,useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { useParams } from 'react-router-dom';
import './ProductFilter.css'; // Import your custom CSS file for styling
import { getProducts } from '../Redux/actions/Actions';
import SizeSelector from '../Product/SizeSelector';  // Import SizeSelector component
import QuantityControl from '../Product/QuantityControl';  // Import QuantityControl component
import { toast, ToastContainer } from 'react-toastify';
import { LoginContext } from '../Context/ContextProvider';



const ProductFilter = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { account, setAccount } = useContext(LoginContext);
  const { products } = useSelector((state) => state.getproductsdata);
  const { category } = useParams("");


  // State to manage the visibility of the popup
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [banner, setBanner] = useState([]);


  useEffect(() => {
    fetchCategories();
    dispatch(getProducts());
  }, [category, dispatch]);

  const handleQuantityChange = (newQuantity) => {
    setQuantity(newQuantity);
  };

  const fetchCategories = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API}/getCategories`);
      if (response.ok) {
        const data = await response.json();
        setBanner(data);
        // console.log(banner);
      } else {
        console.error('Error fetching categories:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  // Function to handle "Add to Cart" button click and open the popup
  const handleAddToCartClick = (product) => {
    setSelectedProduct(product);
    setIsPopupOpen(true);
  };

  // Function to handle "Add to Cart" button click in the popup
  const handleAddToCartPopupClick = async (id) => {
    // Add your API call here to send data to the database
    if (!selectedProduct.selectedSize) {
      toast.error("Please select a size");
      return; // Stop execution if no size is selected
    }
      const response = await fetch(`${process.env.REACT_APP_API}/addcart/${id}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
          selectedProduct,
          selectedSize: selectedProduct.selectedSize,
          quantity: quantity,
        }),
        credentials: "include"
      });

      if(!response.ok){
        toast.warn("please login into your account")
        // console.log("no data");
        setTimeout(() => {
          navigate('/signin');
        }, 500);

        
      }
      //  console.log(response);
      const data1 = await response.json();
      //  console.log(data1);
  if(!response.ok){
    alert("no data");
    // console.log("no data");
    navigate('/signin');
  }
  else{
    toast.success("product added")
    setAccount(data1)
          navigate('/cart');
  }

    setIsPopupOpen(false);
  };



  return (
    <div>
      <div className='w-100 text-center'>
{banner 
      
        .filter((bannerItem) => bannerItem._id === category)
        .map((bannerItem)=>{
          return <img key={bannerItem._id} src={bannerItem.banner}  alt="" className='img-fluid  text-center' />
        })
      
    }
    </div>

    <div className='product-dashboard-container'>
      <div className="product-grid my-5 text-center mx-auto">
        {products
          .filter((product) => product.category === category)
          .map((product) => (
            <div key={product._id} className='product-head'>
              <Link to={`/productone/${product.id}`} style={{textDecoration:'none'}} >
              <div className='product-card' >
                <img src={product.url} alt={product.title.shortTitle} className='product-img'/>
                <p>{product.title.longTitle}</p>
                <div className='d-flex flex-row justify-content-center my-2'>
                  <p className='fw-bold text-dark mrp'>Rs.{product.price.mrp}.00</p>&nbsp;&nbsp;
                  <p className='text-danger fw-bold'>Rs.{product.price.cost}.00</p>
                </div>
              </div>
              </Link>
              <button className='buy-now mt-2 mb-4 w-100' onClick={() => handleAddToCartClick(product)}>
                Add to Cart
              </button>
            </div>
          ))}
      </div>

      {/* Popup for adding size and quantity */}
      {isPopupOpen && selectedProduct && (
        <div className='address-bg'>
          <div className="address-form-too">
            <div className='inner-too'>
              <div className='w-30'><img src={selectedProduct.url} alt="" className='address-img'/></div>
              <div className='w-70'>

              <p style={{'font-size':'18px' , 'font-weight':'500'}}>{selectedProduct.title.longTitle}</p>
            
            {/* Include SizeSelector and QuantityControl components */}
            <SizeSelector
              sizes={['S', 'M', 'L', 'XL']}
              selectedSize={selectedProduct.selectedSize}
              onSelectSize={(size) => setSelectedProduct({ ...selectedProduct, selectedSize: size })}
              required
            />
            
            <QuantityControl
              onQuantityChange={handleQuantityChange}
              quantity={quantity}
            />
            
            {/* Add "Add to Cart" button in the popup */}
            <div className='address-flex '>
            <button onClick={() => handleAddToCartPopupClick(selectedProduct.id)} className=' mt-1 btn btn-dark '>Add to Cart</button>
            
            <button onClick={() => setIsPopupOpen(false)} className=' mt-1 btn border border-2'>Close</button>
            </div>
            

              </div>
            </div>
           
          </div>
        </div>
      )}
      
      <ToastContainer />

    </div>
    </div>
  );
};

export default ProductFilter;
