import React, { useContext, useEffect, useState } from 'react';
import { LoginContext } from '../Context/ContextProvider';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import '../Cart/Cart.css';
import './myorders.css'
import whatapp from '../Assets/whatsapp.png'

const Myorders = () => {
  const [orders, setOrders] = useState([]);
  const { account } = useContext(LoginContext);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const findOrders = () => {
    if (account && account?.orders?.length > 0) {
      const latestOrders = account.orders;
      setOrders(latestOrders);
    }
  };
  const calculateDeliveryDate = (orderDate) => {
    const deliveryDate = new Date(orderDate);
    deliveryDate.setDate(deliveryDate.getDate() + 7);
    return deliveryDate.toDateString();
  };

  const updateOrderStatus = async (orderId, status) => {
    try {
      await fetch(`${process.env.REACT_APP_API}/updateOrderStatus`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ orderId, status }),
      });
      console.log(`Updating order ${orderId} status to ${status}`);
      // After updating the status, you may want to fetch the updated orders
  setShow(false)
  setTimeout(() => {
    findOrders();
  }, 2000);
  
  // Wait for the fetchOrders() to complete before refreshing the page
  setTimeout(() => {
    window.location.reload();
  }, 1000);
      // Wait for the fetchOrders() to complete before opening the details modal
      // setTimeout(() => {
      //   openOrderDetails(orders.find(order => order.orderId === orderId));
      // }, 1000); // Adjust the timeout duration as needed
    } catch (error) {
      console.error('Error updating order status:', error);
    }
  };

  useEffect(() => {
    findOrders();
  }, [account]);

  return (
    <div className='d-flex flex-column justify-content-center align-items-center py-5'>
      <h1 className='order-head'>My Orders</h1>
      {orders.length > 0 ? (
        <div className='mt-3 order-confirmed-div m-auto'>
          {orders.reverse().map((order) => (
            <li key={order._id} className='order-details border border-2 p-3 mb-5' style={{"listStyle":"none"}}>
              <p className='fw-bold fs-5 text-break'>Order No : {order._id}</p>
              <p className='fw-bold fs-6 text-break'>Shipping Address : {order.address}</p>
              <p className='fw-bold fs-6 '>Items : {order.items.length}</p>
              {order.items.map((item) => (
              
              <li key={item._id} className='order-li' style={{"listStyle":"none"}}>
                <div className='row  border p-3'>
              <div className='col-lg-12 '><strong className='text-danger fs-6'>{item.title.longTitle}</strong> - <span className='fw-bold fs-6'>Quantity&nbsp;</span>: <span className='fs-6'>{item.quantity}</span> ,&nbsp; &nbsp; <span className='fw-bold fs-6'>Selected Size&nbsp;</span>:&nbsp;<span className='fs-6'>{item.selectedSize}</span>&nbsp;,&nbsp; &nbsp;<span className='fw-bold fs-6'>Price&nbsp;</span>: <span className='fs-6'>Rs.&nbsp;{item.price.cost ?? 'N/A'}</span><span className='fw-bold fs-6'>&nbsp;,&nbsp;&nbsp;Total Price&nbsp;</span>: <span className='fs-6'>Rs.&nbsp;{item.price.cost*item.quantity ?? 'N/A'}.00</span></div>
                </div>
                
              </li>
            ))}
              <p className='fw-bold fs-6 '>TotalAmount : Rs. {order.totalAmount}.00</p>
              <p className='fw-bold fs-6 '>Estimate delivery : {calculateDeliveryDate(order.orderDate)}</p>
              <p className='fw-bold fs-6 '>Order Date : {order.orderDate}</p>
              <div className='row'>
              <div className='col px-1'>
              <p className='fw-bold fs-6 '>Order Status : </p>
    <div className='order-status-line my-3'>
        <div className='d-flex  justify-content-center align-items-center w-25'><div className={`dot ${order.status === 'Read' || order.status === 'placed' ? 'active' : ''}`}></div><p className=' ms-1 my-0'>Order Placed</p></div>
        <div className='d-flex  justify-content-center align-items-center w-25'><div className={`dot ${order.status === 'Dispatched' ? 'active' : ''}`}></div><p className=' ms-1 my-0'>Dispatched</p></div>
        <div className='d-flex  justify-content-center align-items-center w-25'><div className={`dot ${order.status === 'Delivered' ? 'active' : ''}`}></div><p className=' ms-1 my-0'>Delivered</p></div>
        <div className='d-flex  justify-content-center align-items-center  w-25'><div className={`dot ${order.status === 'cancelled' ? 'red' : ''}`}></div><p className='ms-1   my-0'>Cancelled</p></div>
    </div>
    
</div>
                <div className='col my-auto text-end'>
                <button className='btn btn-outline-secondary' disabled={order.status == 'Dispatched' || order.status == 'Delivered' || order.status == 'cancelled'} onClick={handleShow}>Order Cancel</button>
                <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Order</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Are you sure you want to cancel order?</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="dark" onClick={handleClose}>
            no
          </Button>
          <Button variant="danger" onClick={() => updateOrderStatus(order._id, 'cancelled')}>
            yes
          </Button>
        </Modal.Footer>
      </Modal>
      
                </div>
                
              </div>
              <div className=''>
              <p className='text-danger my-2' style={{'font-size':'12px'}}>
        you can cancel order within 7 hours or till the blue dot is on order placed.
      </p>
              </div>
              <div className='text-right  text-end'>
                <a href={`https://api.whatsapp.com/send?phone=919082326835&text=Hello%20%2C%20%0A%20I%20want%20to%20cancel%20order%20${order._id}%20`} target='_blank'><img src={whatapp} alt=""  className='img-fluid' style={{'height':"32px"}}/></a>
              </div>

              
              
            </li>
          ))}
        </div>
      ) : (
        <p>No order details available.</p>
      )}
    </div>
  );
};

export default Myorders;
