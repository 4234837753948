import React, { useState ,useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoginContext } from '../Context/ContextProvider';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';

const EmailOtpVerification = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [showOtpField, setShowOtpField] = useState(false);
  const {account ,setAccount} =useContext(LoginContext);


  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleOtpChange = (e) => {
    setOtp(e.target.value);
  };

  const handleRequestOtp = async (e) => {
    e.preventDefault();

    try {
      // Request an OTP from the backend
      const sendOtpRes = await fetch(`${process.env.REACT_APP_API}/sendOtp`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: email,
        }),
      });

      const data = await sendOtpRes.json();
      // console.log(data);

      if (sendOtpRes.status === 200) {
        // Successful API response
        setShowOtpField(true);
      } else {
        // Failed API response
        console.error('Failed to send OTP');
      }
    } catch (error) {
      console.error('Error requesting OTP:', error);
    }
  };

  const handleOtpVerification = async (e) => {
    e.preventDefault();

    try {
      // Verify the entered OTP with the backend
      const verifyOtpRes = await fetch(`${process.env.REACT_APP_API}/verifyOtp`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          email: email,
          otp: otp,
        }),
      });

      const data = await verifyOtpRes.json();
      // console.log(data);

      if (verifyOtpRes.status === 200) {
        // Successful OTP verification
        // console.log('User verified and logged in');

        setAccount(data);
        // Redirect the user to the desired page
        toast.success("Welcome" + data.name);

        navigate('/');
      } else {
        // Failed OTP verification
        console.error('Failed to verify and log in user');
        toast.error("Incorrect OTP")
      }
    } catch (error) {
      console.error('Error verifying OTP:', error);
    }
  };

  return (
    <div className='d-flex justify-content-center'>
      <div className='d-flex flex-column signin_form'>
        <form className='d-flex flex-column form'>
          {!showOtpField ? (
            <>
              <h1>Enter your email address</h1>
              <input
                type='email'
                name='email'
                value={email}
                onChange={handleEmailChange}
                placeholder='Enter email address'
                required
              />
              <button onClick={handleRequestOtp}>Request OTP</button>
            </>
          ) : (
            <>
              <h1>Enter OTP</h1>
              <input
                type='text'
                name='otp'
                value={otp}
                onChange={handleOtpChange}
                placeholder='Enter OTP'
                required
              />
              <button onClick={handleOtpVerification}>Verify OTP</button>
            </>
          )}
        </form>
        <ToastContainer />
      </div>
    </div>
  );
};

export default EmailOtpVerification;
