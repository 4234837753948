import React from 'react'
import Bin from '../Assets/bin.png';
import { LoginContext } from '../Context/ContextProvider';
import { useContext } from 'react';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';

const Delete = ({deleteData,get}) => {
    const {account ,setAccount} =useContext(LoginContext)

    const removeData = async(req,res)=>{
        try {
            const res=await fetch( `${process.env.REACT_APP_API}/remove/${deleteData}`,{
                method:'DELETE',
                headers:{
                  Accept: "application/json",
                  "Content-Type": "application/json"
                },
                credentials: "include"
              });
      
              const data = await res.json();
              // console.log(data);

              if(res.status === 400 || !data){
                // console.log("not delete");
              }
              else{
                console.log("data is deleted");
                toast.success("Item Removed");
                setAccount(data);
                setTimeout(() => {
                    get();  
                }, 3000);
                
                
              }

            } catch (error) {
            console.log(error);
        }
    }

  return (
    <>
    <img src={Bin} alt="" onClick={()=>removeData(deleteData)}  className='bin ms-4 mb-3'/>
    <ToastContainer />
    </>
    

  )
}

export default Delete
